import { useContext } from 'react'
import { VideoModalContext } from '../../context/VideoModalContext'
import { getIdFromVideoUrl } from '../../utils/getIdFromVideoUrl'
import { getChannelFromVideoUrl } from '../../utils/getChannelFromVideoUrl'

const VideoBlock = (props) => {
  const { id, title, image, backgroundUrl, modalUrl } = props

  const videoModalContext = useContext(VideoModalContext)

  const videoUrl = (modalUrl) ? modalUrl : ''
  const videoId = getIdFromVideoUrl(videoUrl)
  const videoChannel = getChannelFromVideoUrl(videoUrl)

  // const Assets = image.map((asset, index) => {
  //   return (
  //     <img key={`videoimage-${id}-${index}`} className="VideoBlock-image" loading="lazy" src={asset.url} alt={asset.title}/>
  //   )
  // })

  const openVideoModal = (e) => {
    e.preventDefault()
    document.body.style.overflow = 'hidden'
    videoModalContext.setVideoId(videoId)
    videoModalContext.setVideoChannel(videoChannel)
    videoModalContext.setVideoOpen(true)
  }

  return (
    <div className="VideoBlock">
      <div className="VideoBlock-inner">
        { title &&
        <div className="VideoBlock-title">
          <h2>{title}</h2>
        </div>
        }
        { image &&
        <div className="VideoBlock-figure">

          <div className="VideoBlock-figureWrap">
            {modalUrl &&
            <div onClick={(e) => openVideoModal(e)} className="VideoBlock-play">
              <div className="VideoBlock-playWrap">
                <svg className="VideoBlock-playIcon">
                  <use xlinkHref="#icon-play"/>
                </svg>
              </div>
            </div>
            }

            { (backgroundUrl) ? (
                <video className="VideoBlock-video" playsInline autoPlay muted loop
                       poster={image.length ? image[0].src : null}>
                  <source src={backgroundUrl} type="video/mp4"/>
                </video>
              ) : (
                <img className="VideoBlock-image" loading="lazy" src={image.length ? image[0].url : null}/>
              )
            }
          </div>
        </div>
        }
      </div>
    </div>
  )
}

export default VideoBlock
