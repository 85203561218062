import CtaButton from '../CtaButton/CtaButton'

const Table = (props) => {
  const { title, table, border, buttons } = props

  let modifier = border ? `Table--ruler` : ''

  const TableContent = table ? table.map((item, index) => {
    return (
      <div key={`Table-row-${index}`} className="Table-row">
        <div className="Table-label">{item.label}</div>
        <div className="Table-value">{item.text}</div>
      </div>
    )
  }) : ''

  // If buttons exists we add CtaButton so this can be re-used elsewhere
  const Buttons = (buttons) ? buttons.map((button, index) => {
    return <CtaButton key={`CtaButton-${index}`} {...button} />
  }) : ''

  return (
    <div className={`Table ${modifier}`}>
      <div className="Table-inner">
        {title &&
        <div className="Table-title">
          <h2>{title}</h2>
        </div>
        }
        <div className="Table-content">
          {TableContent}
        </div>
        {buttons &&
        <div className="Table-buttons">
          {Buttons}
        </div>
        }
      </div>
    </div>
  )
}

export default Table
