import CtaButton from '../CtaButton/CtaButton'

const Conversation = (props) => {
  const { title, text, button } = props

  return (
    <div className="Conversation">
      <div className="Conversation-container">
        <div className="Conversation-inner">
          {title &&
          <div className="Conversation-title">
            <h2>{title}</h2>
          </div>
          }
          {text &&
          <div className="Conversation-content">
            <p>{text}</p>
          </div>
          }
          {button &&
          <div className="Conversation-button">
            <CtaButton {...button[0]} />
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Conversation
