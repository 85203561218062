import CtaButton from '../CtaButton/CtaButton'

const Text = (props) => {
  // modifiers title, content: (ul, ol, i, b/strong, p, a), CtaButton
  const { id, alignment, border, title, content, buttons } = props

  // Check
  let modifier = (alignment !== 'default' && alignment ) ? alignment : ''
  modifier = border ? `${modifier} Text--ruler` : modifier

  // if buttons exists we add CtaButton so this can be re-used elsewhere
  const Buttons = (buttons) ? buttons.map((button, index) => {
    return <CtaButton key={`CtaButton-${id}-${index}`} {...button} />
  }) : ''

  // In React we always need to inject HTML using this object literal
  // See: https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
  const contentHTML = () => {
    return { __html: content }
  }

  return (
    <div className={`Text ${modifier}`}>
      <div className="Text-inner">
        {title &&
        <div className="Text-title">
          <h2>{title}</h2>
        </div>
        }
        <div className="Text-content" dangerouslySetInnerHTML={contentHTML()}/>
        {buttons && buttons.length ?(
            <div className="Text-buttons">
              { Buttons }
            </div>
          )
          : ``
        }
      </div>
    </div>
  )
}

export default Text
