import { getVimeoId } from './getVimeoId'
import { getYoutubeId } from './getYoutubeId'

export const getIdFromVideoUrl = (url) => {
  if (~url.indexOf('vimeo')) {
    return getVimeoId(url)
  } else if (~url.indexOf('youtu')) {
    return getYoutubeId(url)
  } else {
    return
  }
}