const Quote = (props) => {
  const { color, quote, author } = props

  let modifier = (color !== 'default' && color) ? ` Quote--${color}` : ` Quote--default`

  return (
    <div className={`Quote${modifier}`}>
      <div className="Quote-container">
        <div className="Quote-inner">
          <div className="Quote-main">
            <div className="Quote-text">
              <div className="Quote-content">
                { quote &&
                  <h2>{quote}</h2>
                }
              </div>

              { author.length ? (
                <div className="Quote-author">
                  { author[0].name &&
                  <p>{author[0].name}</p>
                  }

                  {author[0].role &&
                    <p>— {author[0].role}</p>
                  }
                </div>
                ) : '' }
            </div>
          </div>


          {author.length &&  author[0].image.length ? (
            <div className="Quote-aside">
              <div className="Quote-figure">
                <img className="Quote-image" src={author[0].image[0].url} alt={author[0].image[0].alt} />
              </div>
            </div>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default Quote
