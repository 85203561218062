import { useEffect, useRef, useState } from 'react'
import Swiper from 'swiper'

const Gallery = (props) => {
  const { id, galleryMedia } = props

  const galleryBase = useRef()
  const galleryContainer = useRef()
  const galleryWrapper = useRef()
  const galleryCaption = useRef()
  const galleryCaptionCount = useRef()
  const galleryCaptionCopy = useRef()
  const gallerySlideCount = useRef()
  const galleryPagination = useRef()
  const galleryButtonNext = useRef()
  const galleryButtonPrev = useRef()

  const [isCarousel, setIsCarousel] = useState(false)
  const [carousel, setCarousel] = useState(false)

  const galleryItems = galleryMedia.map((asset, index) => {
    return (
      <div key={`Gallery-${id}-${index}`} className="Gallery-item" data-description={ asset.description }>
        <div className="Gallery-itemWrap">
          <img className="Gallery-image" loading="lazy" src={ asset.url } alt={ asset.title }/>
        </div>
      </div>
    )
  })


  useEffect(()=> {

    const setCarouselCount = (swiper) => {
      const slideCurrent = swiper.realIndex + 1;
      const slideCount = swiper.slides.length - 2; // We remove the ghost slides made for previous and next

      galleryCaptionCount.current.innerText = `${slideCurrent} / ${slideCount}`
      gallerySlideCount.current.innerText = `${slideCurrent} / ${slideCount}`
    }

    const setCarouselCaption = (swiper) => {
      const slideCaption = swiper.slides[swiper.realIndex].dataset.description;

      if (slideCaption) {
        galleryCaption.current.classList.add('has-copy')
        galleryCaptionCopy.current.innerText = slideCaption
      } else {
        galleryCaption.current.classList.remove('has-copy')
        galleryCaptionCopy.current.innerText = ''
      }
    }

    if (isCarousel) {

      const galleryContainerClass = galleryContainer.current.classList[0]
      const galleryWrapperClass = galleryWrapper.current.classList[0]
      const slideCardClass = galleryWrapper.current.firstChild.classList[0]
      const galleryPaginationClass = galleryPagination.current.classList[0]

      const swiper = new Swiper(galleryContainer.current, {
        containerModifierClass: `${galleryContainerClass}--`,
        loop: true,
        wrapperClass: galleryWrapperClass,
        slideClass: slideCardClass,
        slideActiveClass: `${slideCardClass}--active`,
        slideNextClass: `${slideCardClass}--next`,
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: galleryPagination.current,
          modifierClass: `${galleryPaginationClass}--`,
          bulletClass: `${galleryPaginationClass}Bullet`,
          bulletActiveClass: `${galleryPaginationClass}Bullet--active`,
          clickableClass: `${galleryPaginationClass}--clickable`,
          clickable: true
        },
        navigation: {
          nextEl: galleryButtonNext.current,
          prevEl: galleryButtonPrev.current,
          disabledClass: 'is-disabled'
        },
        on: {
          slideChange: (swiper) => {
            setCarouselCount(swiper)
            setCarouselCaption(swiper)
          }
        }
      })

      setCarousel(swiper)
      setCarouselCount(swiper)
      setCarouselCaption(swiper)

    } else if (carousel) {
      carousel.destroy(false, true)
    }
  },[isCarousel])


  useEffect(() => {
    setIsCarousel(galleryMedia.length >= 2)

    const resizeEventListener = () => {
      setIsCarousel(galleryMedia.length >= 2)
    }

    window.addEventListener('resize', resizeEventListener)
    return () => window.removeEventListener('resize', resizeEventListener)
  }, [])


  return (
    <div className={`Gallery`}  ref={galleryBase}>
      <div className="Gallery-inner">

        <div className="Gallery-slideCount">
          <p ref={gallerySlideCount} />
        </div>

        <div className="Gallery-figure">
          <div className="Gallery-figureWrap">
            <div className="Gallery-container" ref={galleryContainer}>
              <div className="Gallery-wrapper" ref={galleryWrapper}>
                { galleryItems }
              </div>

              {isCarousel &&
                <>
                <div className="Gallery-pagination" ref={galleryPagination} />

                <div className="Gallery-navigation Gallery-navigation--prev" onClick={()=>{ carousel.slidePrev() }}  ref={galleryButtonPrev}>
                  <div className="Gallery-navigationButton">
                    <svg className="Gallery-navigationIcon">
                      <use xlinkHref="#icon-carousel-prev"/>
                    </svg>
                  </div>
                </div>

                <div className="Gallery-navigation Gallery-navigation--next" onClick={() => { carousel.slideNext() }} ref={galleryButtonNext}>
                  <div className="Gallery-navigationButton">
                    <svg className="Gallery-navigationIcon">
                      <use xlinkHref="#icon-carousel-next"/>
                    </svg>
                  </div>
                </div>
                </>
              }
            </div>
          </div>
        </div>

        <div className="Gallery-caption" ref={galleryCaption}>
          <div className="Gallery-captionWrap">
            <p>
              <span className="Gallery-captionCount" ref={galleryCaptionCount} />
              <span className="Gallery-captionSplit"> — </span>
              <span className="Gallery-captionCopy" ref={galleryCaptionCopy} />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
