import Link from 'next/link'

const LinkCard = (props) => {
  const { color, link, title, image } = props

  let modifier = (color !== 'default') ? ` LinkCard--${color}` : ''

  const LinkCardContent = (
    <>
      <div className="LinkCard-main">
        <div className="LinkCard-content">
          {title &&
          <div className="LinkCard-title">
            <p>{title}</p>
          </div>
          }
          <div className="LinkCard-label">
            <span className="LinkCard-labelText">{link.label ? link.label : link.text}</span>
            <svg className="LinkCard-labelArrow">
              <use xlinkHref="#icon-arrow-right"/>
            </svg>
          </div>
        </div>
      </div>

      {image.length ? (
        <div className="LinkCard-aside">
          <div className="LinkCard-figure">
            <img className="LinkCard-image" src={image[0].url} alt={image[0].title}/>
          </div>
        </div>
      ) : ''
      }
    </>
  )

if (props.link.type === 'entry') {
//   link.url = '/' + link.url
// console.log("Link", props.link);
}

  const LinkCardElement = props.link.type === 'entry' ? (
    
    <Link href={props.link.url.replace(process.env.PROJECT_URL, '/')}>
      <a className={`LinkCard${modifier}`}>
        {LinkCardContent}
      </a>

    </Link>
  ) : (
    <a href={link.url} className={`LinkCard${modifier}`} target={`_blank`} rel={`noreferrer noopener`}>
      {LinkCardContent}
    </a>
  )

  return LinkCardElement
}

export default LinkCard
