import Link from 'next/link'

const CtaButton = (props) => {
  const { button } = props

  if(!button.url) {
    return <></>
  }

  const modifier = (button.style !== 'default' && button.style) ? button.style : ''
  const url = (button.type === 'entry') ? button.url.replace(process.env.PROJECT_URL, '/') : button.url

  const ctaElement = button.type !== 'entry' ? (
    <a className={`CtaButton ${modifier}`} target={button.target} href={url}>
      <span className="CtaButton-label">{button.label}</span>
      <svg className="CtaButton-arrow">
        <use xlinkHref="#icon-arrow-right"/>
      </svg>
    </a>
  ) : (
    <Link href={url}>
      <a className={`CtaButton ${modifier}`} >
        <span className="CtaButton-label">{button.label}</span>
        <svg className="CtaButton-arrow">
          <use xlinkHref="#icon-arrow-right"/>
        </svg>
      </a>
    </Link>
  )

  return ctaElement
}

export default CtaButton
