const ImageBlock = (props) => {
  const { id, alignment, image, caption } = props

  let modifier = (alignment !== 'default' && alignment) ? `ImageBlock--${alignment}` : ''
  modifier = (image.length === 2) ? 'ImageBlock--split' : modifier

  const Assets = image.map((asset, index) => {
    return (
      <div key={`ImageBlock-${id}-${index}`} className="ImageBlock-figureWrap">
        <img className="ImageBlock-image" loading="lazy" src={asset.url} alt={asset.title}/>
      </div>
    )
  })

  return (
    <div className={`ImageBlock ${modifier}`}>
      <div className="ImageBlock-container">
        <div className="ImageBlock-inner">
          {image &&
          <div className="ImageBlock-figure">
            {Assets}
          </div>
          }
          {caption &&
          <div className="ImageBlock-caption">
            <p>— {caption}</p>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ImageBlock
