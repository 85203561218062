import CtaButton from '../components/CtaButton/CtaButton'
import { useEffect } from 'react'

const Custom404 = () => {
  useEffect(() => {
    document.querySelector('body').classList.add('is-generic')
    return () => {
      document.querySelector('body').classList.remove('is-generic')
    }
  }, [])

  return (
    <div className={`Error`}>
      <div>
        <h1 className={`Error-title`}>404<br/>Page not found</h1>

        <div className={`Error-button`}>
          <CtaButton button={{ url: '/', type: 'entry', label: 'Back to home', style: 'CtaButton--hollow' }}/>
        </div>
      </div>
    </div>
  )
}

export default Custom404
